import type { FormEncType } from "@remix-run/react";
import { useCallback } from "react";
import type { useFetcher as useFetcherRR } from "react-router-dom";
import { $path } from "remix-routes";
import { useFetcherWithPromise } from "~hooks/useFetcherWithPromise.tsx";

type ActionOptions = {
  encType?: FormEncType;
};

export const createActionFetcherFactory = <
  P extends Record<string, any> = {},
  Q extends Record<string, any> = P,
>(
  apiPath: string,
  actionOptions?: ActionOptions,
) => {
  return (opts?: Parameters<typeof useFetcherRR>[0]) => {
    const { submit: submitFetcher, ...rest } = useFetcherWithPromise(opts);

    const submit = useCallback(
      async (formData: FormData, params?: P, query?: Q) => {
        const response = await submitFetcher(formData, {
          method: "POST",
          action: $path(apiPath, params, query),
          ...actionOptions,
        });
        return response;
      },
      [submitFetcher],
    );

    const submitData = useCallback(
      async (data, params?: P, query?: Q) => {
        const response = await submitFetcher(data, {
          method: "POST",
          action: $path(apiPath, params, query),
          ...actionOptions,
        });
        return response;
      },
      [submitFetcher],
    );

    const submitDataWithPromise = async (data, params?: P, query?: Q) => {
      const response = await submitFetcher(data, {
        method: "POST",
        action: $path(apiPath, params, query),
        ...actionOptions,
      });
      return response;
    };

    return { submit, submitData, submitDataWithPromise, ...rest };
  };
};
